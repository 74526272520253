import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import BlogDetailsStandard from "./pages/blog/BlogDetailsStandard";
import Team from "./pages/other/Team";
import Partners from "./pages/home/HomeCorpComponents/body/Blog/Partners";


const HomeCorp = lazy(() => import("./pages/home/HomeKempesCorporate"));
// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
// const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const InfoPage = lazy(() => import("./pages/other/InfoPages"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            {/* Home pages */}
            <Route path={process.env.PUBLIC_URL + "/"} element={<HomeCorp />} />
            <Route
              path={process.env.PUBLIC_URL + "/about"}
              element={<About />}
            />
            <Route path={process.env.PUBLIC_URL + "/team"} element={<Team />} />
            <Route
              path={process.env.PUBLIC_URL + "/partners"}
              element={
                <Partners spaceBottomClass="pb-50" spaceTopClass="pt-50" />
              }
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact fullPage={true} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/info/:slug"}
              element={<InfoPage fullPage={true} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
