import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import partnersData from "../../../../blog/partners-data.json";
import BlogFeaturedThreeSingleCorp from "./BlogFeaturedThreeSingleCorp";
import SectionTitle from "../../../../../components/section-title/SectionTitle";
import { Fragment } from "react";
import SEO from "../../../../../components/seo";
import { seoData } from "../../../../../components/seoData";
import LayoutOne from "../../../../../layouts/LayoutOne";
import BreadcrumbWrap from "../../../../../wrappers/breadcrumb/Breadcrumb";
import * as baseService from '../../../../../services/baseService'
import { useState } from "react";
import { useEffect } from "react";
const Partners = ({ spaceTopClass, spaceBottomClass }) => {
const [partners,setPartners]= useState(null)

useEffect(()=>{
 baseService.getAllPartners()
 .then(result=>{
    setPartners(result.partners);
 })
 .catch(err=>{
    console.log(err);
 });
},[])

  return (
    <Fragment>
      <SEO
        title={seoData.title}
        titleTemplate={seoData.homecorp.titleTemplate}
        description={seoData.homecorp.description}
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-2"
        headerTop="visible"
      >
        <BreadcrumbWrap
          pages={[
            {
              label: "Кемпес",
              path: process.env.PUBLIC_URL + "/",
            },
            {
              label: "Партньори",
            },
          ]}
        />
        <div className={clsx("blog-area", spaceTopClass, spaceBottomClass)}>
          <div className="container-fluid">
            <SectionTitle
              titleText="НАШИТЕ ПАРТНЬОРИ"
              subtitleText="Професионалистите, с които партнираме и чиито продукти предлагаме."
              positionClass="text-center"
              spaceClass="mb-55"
              borderClass="no-border"
            />
            <div className="row">
              {partners &&
                partners.map((partner) => (
                  <div className="col-lg-3 col-sm-6" key={partner.id}>
                    <BlogFeaturedThreeSingleCorp partner={partner} />
                  </div>
                ))}
            </div>
            {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-btn text-left mt-20">
                        <Link
                        className="btn btn-black"
                        to="/partners"
                        >
                        Виж всички...
                        </Link>
                        </div>
                        </div>
                    </div> */}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

Partners.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default Partners;
