import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  const language = useSelector((state) => state.language.languageShort);
  return (
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo">
        <Link to={process.env.PUBLIC_URL + "/"}>
          <img alt="" src={process.env.PUBLIC_URL + footerLogo} />
        </Link>
      </div>
      <p>
    
        <br />
        Уеб дизайн и разработка
        <br />
        <a href="https://siweb.bg/" rel="noopener noreferrer" target="_blank">
          <b>{language === "bg" && "siweb.bg"}</b>
          {language === "en" && "Web design and development siweb.bg"}
        </a>
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
