import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import productsData from "../../data/products_data";
// import * as getProductsData from "../../data/getProductsData";
import * as productsData from "../../data/productsData";

// import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { ConvertToJson } from "../../helpers/convertCsvToJson";
const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const language = useSelector((state) => state.language.languageShort);
  const { products } = useSelector((state) => state.product);



  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          <li>
            <Link to={`/`}>Начало</Link>
          </li>
          <li>
            <a href="https://shop.kempes.bg/">Магазин</a>
          </li>
          <li>
            <Link to={`/team`}>Екип</Link>
          </li>
          <li>
            <Link to={`/partners`}>Партньори</Link>
          </li>
          <li>
            <Link to={`/about`}>За нас</Link>
          </li>
          <li>
            <Link to={`/contact`}>Контакти</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
