export const seoData = {
    title: "КЕМПЕС",
    home: {
        titleTemplate: "Начало",
        description:
            "Начална страница на КЕМПЕС React eCommerce онлайн магазин.",
    },
    homecorp: {
        titleTemplate: "Начало",
        description: "Начална страница на КЕМПЕС ЕООД.",
    },
    homeGridBanner: {
        titleTemplate: "Начало",
        description: "Начален Банер на КЕМПЕС React eCommerce онлайн магазин.",
    },
    blog: {
        titleTemplate: "Блог",
        description: "Блог на КЕМПЕС ЕООД.",
    },
    partner: {
        titleTemplate: "Партньори",
        description: "Партньори на КЕМПЕС ЕООД.",
    },
    about: {
        titleTemplate: "За Нас",
        description:
            "За Нас страница на КЕМПЕС React eCommerce онлайн магазин.",
    },
    cart: {
        titleTemplate: "Количка",
        description: "Количка на КЕМПЕС React eCommerce онлайн магазин.",
    },
    checkout: {
        titleTemplate: "Поръчка",
        description:
            "Страница за поръчка на КЕМПЕС React eCommerce онлайн магазин.",
    },
    compare: {
        titleTemplate: "Сравни",
        description:
            "Страница за сравнение на КЕМПЕС React eCommerce онлайн магазин.",
    },
    contact: {
        titleTemplate: "Контакти",
        description: "Контакти на КЕМПЕС React eCommerce онлайн магазин.",
    },
    loginRegister: {
        titleTemplate: "Вход / Регистрация",
        description:
            "Вход / Регистрация на КЕМПЕС React eCommerce онлайн магазин.",
    },
    myAccount: {
        titleTemplate: "Моят Профил",
        description: "Моят Профил на КЕМПЕС React eCommerce онлайн магазин.",
    },
    notFound: {
        titleTemplate: "404",
        description: "Страница 404 на КЕМПЕС React eCommerce онлайн магазин.",
    },
    returnPolicy: {
        titleTemplate: "Политика за Връщане",
        description:
            "Политика за Връщане на КЕМПЕС React eCommerce онлайн магазин.",
    },
    supportAndGuarantie: {
        titleTemplate: "Поддръжка и Гаранция",
        description:
            "Поддръжка и Гаранция на КЕМПЕС React eCommerce онлайн магазин.",
    },
    termsAndConditions: {
        titleTemplate: "Условия за ползване",
        description:
            "Условия за ползване на КЕМПЕС React eCommerce онлайн магазин.",
    },
    wishlist: {
        titleTemplate: "Желани",
        description:
            "Страница за желани продукти на КЕМПЕС React eCommerce онлайн магазин.",
    },
    shop: {
        titleTemplate: "Магазин",
        description: "КЕМПЕС React eCommerce онлайн магазин.",
    },
    product: {
        titleTemplate: "Продукт",
        description: "Продукт - КЕМПЕС React eCommerce онлайн магазин.",
    },
};
