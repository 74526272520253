import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";

const SectionTitleWithText = ({
  spaceTopClass,
  spaceBottomClass,
  title,
  content,
}) => {
  const { slug } = useParams();

  return (
    <div className={clsx("welcome-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        {!slug ? (
          <div className="container">
            <div className="welcome-content text-center">
              <h5>Who Are We</h5>
              <h1>Welcome To KEMPES</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt labor et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut aliquip ex ea commo consequat irure{" "}
              </p>
            </div>
          </div>
        ) : (
          <div className="welcome-content text-center">
            <h1>{title}</h1>
            {ReactHtmlParser(content)}
            {/* {content} */}
          </div>
        )}
      </div>
    </div>
  );
};

SectionTitleWithText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default SectionTitleWithText;
