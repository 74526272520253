import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import * as productsData from "../../../data/productsData";
// import { useTranslation } from "react-i18next";

const MobileNavMenu = () => {
  const language = useSelector((state) => state.language.languageShort);
  // const { t } = useTranslation();
  const { products } = useSelector((state) => state.product);

  // let categories = [];

  // products.forEach((product) => {
  //   product.category.forEach((category, index) => {
  //     const subCategory = product["sub-category"][index];

  //     // Check if the category already exists in the categories array
  //     const categoryIndex = categories.findIndex(
  //       (cat) => cat.name === category
  //     );

  //     if (categoryIndex === -1) {
  //       // Category does not exist, create a new category object
  //       categories.push({
  //         name: category,
  //         subCategories: [subCategory],
  //         products: [product],
  //       });
  //     } else {
  //       // Category already exists, add the product to the existing category
  //       const existingSubCategories = categories[categoryIndex].subCategories;

  //       // Check if the sub-category already exists in the existingSubCategories array
  //       const subCategoryIndex = existingSubCategories.findIndex(
  //         (subCat) => subCat === subCategory
  //       );

  //       if (subCategoryIndex === -1) {
  //         categories[categoryIndex].subCategories.push(subCategory);
  //       }

  //       categories[categoryIndex].products.push(product);
  //     }
  //   });
  // });

  const productsCategoryData =
    productsData.sortByCategoryAndSubcategory(products);

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li className="menu-item">
          <Link to={`/`}>Начало</Link>
        </li>
        <li className="menu-item">
          <a href="https://shop.kempes.bg/">Магазин</a>
        </li>
        <li className="menu-item">
          <Link to={`/team`}>Екип</Link>
        </li>
        <li className="menu-item">
          <Link to={`/partners`}>Партньори</Link>
        </li>
        <li className="menu-item">
          <Link to={`/about`}>За нас</Link>
        </li>
        <li className="menu-item">
          <Link to={`/contact`}>Контакти</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
