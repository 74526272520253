function sortByCategory(products) {
  const productsData = {};

  products.forEach((product) => {
    // Extract the category from the product
    const [category] = product.category;

    // Check if the category exists in the 'productsData' object
    if (category in productsData) {
      productsData[category].push(product);
    } else {
      // If it doesn't exist, create a new key with the category name
      productsData[category] = {};
      productsData[category] = [product];
    }
  });

  return productsData;
}

function sortByCategoryAndSubcategory(products) {
  const productsData = {};
  products.forEach((product) => {
    // Extract the category from the product
    const [category] = product.category;
    const [subcategory] = product["sub-category"];

    // Check if the category exists in the 'productsData' object
    if (category in productsData) {
      if (subcategory in productsData[category]) {
        productsData[category][subcategory].push(product);
      }
      // If it exists, push the product to the category key
      else {
        productsData[category][subcategory] = [product];
      }
    } else {
      // If it doesn't exist, create a new key with the category name
      productsData[category] = {};
      productsData[category][subcategory] = [product];
    }
  });

  return productsData;
}

function getProductsCategories(products) {
  const productsCategories = [];
  products.forEach((product) => {
    const [category] = product.category;
    if (!productsCategories.includes(category)) {
      productsCategories.push(category);
    }
  });
  return productsCategories;
}

function getProductsSubcategories(products) {
  const productsSubcategories = [];
  products.forEach((product) => {
    const [subcategory] = product["sub-category"];
    if (!productsSubcategories.includes(subcategory)) {
      productsSubcategories.push(subcategory);
    }
  });
  return productsSubcategories;
}

function getProductsTags(products) {
  const productsTags = [];
  products.forEach((product) => {
    product.tags.forEach((tag) => {
      if (!productsTags.includes(tag)) {
        productsTags.push(tag);
      }
    });
  });
  return productsTags;
}

function getProductsCategoriesAndSubcategories(products) {
  const productsCategoriesAndSubcategories = [];
  products.forEach((product) => {
    const [category] = product.category;
    const [subcategory] = product["sub-category"];
    if (!productsCategoriesAndSubcategories.includes(category + subcategory)) {
      productsCategoriesAndSubcategories.push(category + subcategory);
    }
  });
  return productsCategoriesAndSubcategories;
}

function getDiscountedProducts(products) {
  const discountedProducts = [];
  products.forEach((product) => {
    if (product.discount) {
      discountedProducts.push(product);
    }
  });
  return discountedProducts;
}

function getNewProducts(products) {
  const newProducts = [];
  products.forEach((product) => {
    if (product.new) {
      newProducts.push(product);
    }
  });
  return newProducts;
}

function getNewAndDiscountedProducts(products) {
  const newAndDiscountedProducts = [];
  products.forEach((product) => {
    if (product.discount && product.new) {
      newAndDiscountedProducts.push(product);
    }
  });
  return newAndDiscountedProducts;
}

export {
  sortByCategory,
  sortByCategoryAndSubcategory,
  getProductsCategories,
  getProductsSubcategories,
  getProductsTags,
  getProductsCategoriesAndSubcategories,
  getDiscountedProducts,
  getNewProducts,
  getNewAndDiscountedProducts,
};
