const MobileWidgets = ({ language }) => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{" "}
              <a href="#">
                {language === "bg" && (
                  <span>
                    Обадете ни се:
                    <br />
                    0885 404 050; <br />
                    0884 209 232
                  </span>
                )}
                {language === "en" && (
                  <span>
                    Call us:
                    <br />
                    +359 (0)885 404 050; <br />
                    +359 (0)884 209 232
                  </span>
                )}
              </a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a href="mailto:office@kempes.bg">office@kempes.bg</a>
            </li>
          </ul>
        </div>
        <br/>
        <div className="copyright mb-30">
          {/* <div className="footer-logo">
            <a href="/">
              <img alt="" src="/assets/img/logo/logo_kempes100x61.png" />
            </a>
          </div> */}
          © 2023{" "}
          <a
            className="desktop-menu-a"
            href="https://kempes.bg/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <b>КЕМПЕС</b>
          </a>
          <br /> Всички права запазени.
          <br />
          Уеб дизайн и разработка
          <br />
          <a
            className="desktop-menu-a"
            href="https://siweb.bg/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <b>siweb.bg</b>
          </a>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      {/* <div className="off-canvas-widget-social">
        <a href="//twitter.com" title="Twitter">
          <i className="fa fa-twitter"></i>
        </a>
        <a href="//instagram.com" title="Instagram">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="//facebook.com" title="Facebook">
          <i className="fa fa-facebook"></i>
        </a>
        <a href="//pinterest.com" title="Pinterest">
          <i className="fa fa-pinterest"></i>
        </a>
      </div> */}
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
