import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState: {
    languageName: "Български",
    languageShort: "bg",
  },
  reducers: {
    setLanguage(state, action) {
      // console.log("state", state);
      // console.log("action", action);
      const languageShort = action.payload;

      if (languageShort === "bg") {
        return {
          languageShort,
          languageName: "Български",
        };
      }
      if (languageShort === "en") {
        return {
          languageShort,
          languageName: "English",
        };
      }
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
