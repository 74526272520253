import PropTypes from "prop-types";
import clsx from "clsx";
import SectionTitleTwo from "../../components/section-title/SectionTitleTwo";
import teamMemberData from "../../data/team-members/team-member-one.json";
import TeamMemberOneSingle from "../../components/team-member/TeamMemberOneSingle";
import * as baseService from '../../services/baseService'
import { useState } from "react";
import { useEffect } from "react";
const TeamMemberOne = ({ spaceTopClass, spaceBottomClass }) => {
const [team,setTeam] = useState(null)

useEffect(()=>{
     baseService.getAllTeamMembers()
     .then(result=>{
      setTeam(result.teamMembers);
     })
     .catch(err=>{
      console.log(err);
     })
},[])

  return (
    <div className={clsx("team-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        {/* section title */}
        <SectionTitleTwo
          titleText="Екип"
          subTitleText="Кои сме ние ?"
          positionClass="text-center"
          spaceClass="mb-60"
        />

        <div className="row">
          {team &&
            team.map((single, key) => (
              <div className="col-lg-3 col-md-6 col-sm-6" key={key}>
                <TeamMemberOneSingle data={single} spaceBottomClass="mb-30" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

TeamMemberOne.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default TeamMemberOne;
