import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import BannerOne from "../../wrappers/banner/BannerOne";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";

const Team = () => {
  let { pathname } = useLocation();
  const language = useSelector((state) => state.language.languageShort);

  const KEMPESBreadcrumbLabel = { bg: "КЕМПЕС", en: "KEMPES" };
  const AboutUsBreadcrumbLabel = { bg: "Екип", en: "About Us" };

  return (
    <Fragment>
      <SEO
        titleTemplate="About us"
        description="About page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            {
              label: KEMPESBreadcrumbLabel[language],
              path: process.env.PUBLIC_URL + "/",
            },
            {
              label: AboutUsBreadcrumbLabel[language],
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />

        <TeamMemberOne spaceTopClass="pt-95" spaceBottomClass="pb-70" />

        <BrandLogoSliderOne spaceBottomClass="pb-70" />
      </LayoutOne>
    </Fragment>
  );
};

export default Team;
