import axios from "axios";
import { baseUrl } from "../baseUrl";

export const getAllPages = async () => {
    try {
        const response = await axios.get(`${baseUrl}/api/pages_json`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getAllTeamMembers = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/team_members_json`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getAllPartners = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/partners_json`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};