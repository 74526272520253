import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { baseStorageUrl } from "../../../../../baseUrl";

const BlogFeaturedThreeSingleCorp = ({ partner }) => {
    // console.log(partner);
    return (
      <div className="blog-wrap mb-30 scroll-zoom">
        <div className="blog-img">
          {/* <Link to={"/partner" + partner.slug}> */}
          <img src={baseStorageUrl + partner.image} alt="" />
          {/* </Link> */}

          {/* Производител и т.н бадж */}
          {/* <div className="blog-category-names blog-category-names--style2">
                    {partner.category.map((singleCategory, key) => {
                        return (
                            <React.Fragment key={key}>
                                <span
                                    className={
                                        singleCategory === "БГ Производител"
                                            ? "green"
                                            : "red"
                                    }
                                    key={key}
                                >
                                    {singleCategory}
                                </span>
                            </React.Fragment>
                        );
                    })}
                </div> */}
        </div>
        <div className="blog-content-wrap">
          <div className="blog-content blog-content--style2 text-center">
            <h2>
              {/* <Link to={"/partner" + partner.slug}>{partner.name}</Link> */}
              {partner.name}
            </h2>
            <h3>
              {/* <Link to={"/partner" + partner.slug}>{partner.slogan}</Link> */}
              {partner.slogan}
            </h3>
            <span>
              <Link to={partner.website_link}>{partner.website_link}</Link>
            </span>
          </div>
        </div>
      </div>
    );
};

BlogFeaturedThreeSingleCorp.propTypes = {
    partner: PropTypes.shape({}),
};

export default BlogFeaturedThreeSingleCorp;
