import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrency } from "../../../store/slices/currency-slice";
import { setLanguage } from "../../../store/slices/language-slice";

const MobileLangCurrChange = () => {
  // const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const language = useSelector((state) => state.language.languageShort);

  // const changeLanguageTrigger = e => {
  //   const languageCode = e.target.value;
  //   i18n.changeLanguage(languageCode);
  //   closeMobileMenu();
  // };

  const changeLanguageTrigger = (e) => {
    const languageShort = e.target.value;
    console.log(languageShort);
    dispatch(setLanguage(languageShort));
  };

  const setCurrencyTrigger = (e) => {
    const currencyName = e.target.value;
    dispatch(setCurrency(currencyName));
    closeMobileMenu();
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="mobile-menu-middle">
      {/* <div className="lang-curr-style">
        <span className="title mb-2">
          {language === "bg" && "English"}
          {language === "en" && "Български"}
        </span>
        <select value={language} onChange={changeLanguageTrigger}>
          <option value="bg">Български</option>
          <option value="en">English</option>
          <option value="fn">French</option>
          <option value="de">Germany</option>
        </select>
      </div> */}
      {/* <div className="lang-curr-style">
        <span className="title mb-2">Choose Currency</span>
        <select value={currency.currencyName} onChange={setCurrencyTrigger}>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
        </select>
      </div> */}
    </div>
  );
};

export default MobileLangCurrChange;
