import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import * as baseService from '../../services/baseService'
import { useState } from "react";
import { useEffect } from "react";
const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const language = useSelector((state) => state.language.languageShort);
  const [pages,setPages] = useState(null)
  useEffect(()=>{
      baseService.getAllPages()
      .then(result=>{
        setPages(result)
      })
      .catch(err=>{
        console.log(err);
      })
  },[])
 
  return (
    <footer
      className={clsx(
        "footer-area",
        backgroundColorClass,
        spaceTopClass,
        spaceBottomClass,
        extraFooterClass,
        spaceLeftClass,
        spaceRightClass
      )}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          >
            {/* footer copyright */}
            <FooterCopyright
              footerLogo="/assets/img/logo/logo_kempes100x61.png"
              spaceBottomClass="mb-30"
            />
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-5 col-sm-4"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>
                  {language === "bg" && "ЗА НАС"}
                  {language === "en" && "ABOUT US"}
                </h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>
                      {language === "bg" && "За нас"}
                      {language === "en" && "About Us"}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/team"}>
                      {language === "bg" && "Екип"}
                      {language === "en" && "Contact us"}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/partners"}>
                      {language === "bg" && "Партньори"}
                      {language === "en" && "Contact us"}
                    </Link>
                  </li>

                  {/* <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Orders tracking
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-5 col-sm-4"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-30"
              }`}
            >
              <div className="footer-title">
                <h3>
                  {language === "bg" && "ПОЛЕЗНО"}
                  {language === "en" && "USEFUL LINKS"}
                </h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <a href="https://shop.kempes.bg/">
                      {language === "bg" && "Магазин"}
                      {language === "en" && "Store location"}
                    </a>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      {language === "bg" && "Контакти"}
                      {language === "en" && "Contact us"}
                    </Link>
                  </li>
                  {pages &&
                    pages.map((el) => (
                      <li>
                        <Link to={`/info/${el.slug}`}>
                          {el.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
            }`}
          >
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            />
          </div> */}
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
